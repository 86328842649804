<template>
  <div class="content" :id="contentId"></div>
</template>

<script>
  import * as echarts from "echarts";
  export default {
    data() {
      return {
        mychart: null,
        chart: {
          title: {
            text: ''
          },
          // color: ["#7471FE", "#00F3FF", "#0191F2", "#FEDE65", "#189ABC"],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
            // backgroundColor: "rgba(0, 0, 0,0.5)",
            // borderColor: "#1198B9",
            textStyle: {
              fontSize: 14,
              // color: "#FFFFFF",
            },
            formatter: function (params) {
              console.log(params)
              let relVal = null
              for (let i = 0; i < params.length; i++) {
                if (params[i].value) {
                  // params[i].seriesType=='line'?'%':
                  if (params[i].seriesType == 'bar') {
                    relVal += "<br/>" +
                      params[i].axisValue + '年' +
                      ":  " +
                      params[i].value +
                      "万吨;";
                  }else{
                    relVal += "<br/>" +
                      params[i].axisValue + '年' +
                      ":  " +
                      params[i].value +
                      "%;";
                  }

                } else {
                  if (params[i].seriesType == 'bar') {
                    relVal += "<br/>" +
                      params[i].axisValue + '年' +
                      ":  " +
                      params[i].value +
                      "万吨;";
                  }else{
                    relVal += "<br/>" +
                      params[i].axisValue + '年' +
                      ":  " +
                      params[i].value +
                      "%;";
                  }
                }

              }
              return relVal.slice(9)
            }
          },
          legend: {
            itemHeight: 11,
            itemWidth: 11,
            icon: "roundRect",
            borderRadius: [5, 5, 5, 5],
            textStyle: {
              // color: "#B8D3F0",
            },
            right: 30,
            top: 10,
          },
          grid: {
            left: "60px",
            right: "50px",
            top: "60px",
            bottom: "30px",
          },
          xAxis: [
            {
              type: "category",
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  width: 0.75,
                  type: "dashed",
                },
              },
              axisLabel: {
                //       padding: [4, 10, 0, 20],
                // 		interval:0,
                // rotate:"45" //表示的角度倾斜45度
                formatter: function (
                  value //X轴的内容
                ) {
                  var ret = ""; //拼接加\n返回的类目项
                  var max = 9; //每行显示的文字字数
                  var val = value.length; //X轴内容的文字字数
                  var rowN = Math.ceil(val / max); //需要换的行数
                  if (rowN > 1) {
                    //判断 如果字数大于2就换行
                    for (var i = 0; i < rowN; i++) {
                      var temp = ""; //每次截取的字符串
                      var start = i * max; //开始截取的位置
                      var end = start + max; //结束截取的位置
                      temp = value.substring(start, end) + "\n";
                      ret += temp; //最终的字符串
                    }
                    return ret;
                  } else {
                    return value;
                  }
                },
              },
              data: [],
            },
          ],
          yAxis: [
            {
              type: "value",
              nameTextStyle: {
                padding: [6, 0, 0, 8], // 四个数字分别为上右下左与原位置距离
                // color: "#B8D3F0",
              },
              splitLine: {
                show: true,
                lineStyle: {
                  width: 0.75,
                  type: "dotted",
                },
              },

              axisLine: {
                show: true,
                lineStyle: {
                  // 111
                  // 属性lineStyle控制线条样式
                  // color: [
                  //   [0.2, "#c23531"],
                  //   [0.8, "#63869e"],
                  //   [1, "#91c7ae"],
                  // ],
                },
              },
              axisLabel: {
              },
            },
            {
              type: "value",
              name: "",
              // min: -15,
              // max: 10,
              // interval: 5,
              axisLabel: {
                formatter: "{value} %",
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: "#424A90",
                  width: 0.75,
                  type: "dotted",
                },
              },

              axisLine: {
                show: true,
                lineStyle: {
                  // 属性lineStyle控制线条样式
                  color: [
                    [0.2, "#c23531"],
                    [0.8, "#63869e"],
                    [1, "#91c7ae"],
                  ],
                },
              },
            },
          ],
          series: [],
        },
      };
    },
    created() { },
    mounted() {
      // console.log(this.data, '----1---')
      // this.formatData(this.data)
    },
    beforeDestroy() {
      // 在组件销毁之前清除自定义事件
      this.$bus.$off("onresize");
    },
    props: {
      data: Object | Array,
      contentId: String,
    },
    watch: {
      data: {
        handler(newVal) {
          this.formatData(JSON.parse(JSON.stringify(newVal)));
        },
        deep: true,
      },
      contentId(newVal) {
        this.contentId = newVal;
      },
    },
    methods: {
      formatData(data) {
        this.chart.xAxis[0].data = data.items[0].values.map((item) => item.lateralAxis);
        this.chart.yAxis[0].name = data.unit;
        // this.chart.title.text=data.title
        console.log(data, '-------')
        // if (data.ext.color && data.ext.color.length > 0) {
        //   this.chart.color = data.ext.color;
        // }
        let list = null;
        let list1 = null;
        data.items.forEach((item) => {
          //console.log(item);
          if (item.style == 2) {
            list1 = item;
          } else {
            list = item;
          }
        });
        this.chart.series = [
          {
            name: list1.title,
            type: "line",
            yAxisIndex: 1,
            data: list1.values.map((item) => item.value),
          },
          {
            name: list.title,
            type: "bar",
            barWidth: 30,
            label: {
              show: true,
              color: "#FFFFFF",
              position: "top",
            },
            data: list.values.map((item) => item.value == 0 ? '' : item.value),
          },
        ];
        this.init();
      },
      init() {
        this.mychart = echarts.init(document.getElementById(this.contentId));
        this.mychart.setOption(this.chart);
        this.$bus.$on("onresize", () => {
          this.mychart.resize();
        });
        console.log(this.chart);
      },
    },
  };
</script>

<style scoped lang="scss">
  .content {
    width: 100%;
    height: calc(100% - 30px);
  }
</style>